.App {
  text-align: center;
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  /* Styles */
  html {  
      margin: 0px auto;
      font-family:Verdana, Geneva, Tahoma, sans-serif;
      background-color:#22272C;
      color:#FFFFFF;
      font-weight: 600;
  }

  body, #root {
      width: 90%;
      margin:0px auto;
  }
  nav {margin: 0px auto;
       width: 600px;
  }
  .logo {
      text-align:center;
      border:5px solid white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      width:95px;
      background-color: white;
      color:#22272C;}
  a {
      color:#B9D0E1;
      text-decoration: none;
  }
  a:hover {
      color:	#4e92c7;
  }
  a:visited {
      color: #4e92c7;
  }
  h1 { max-width:50%;margin-left:50px;font-family:Verdana, Geneva, Tahoma, sans-serif;font-size:22px;text-transform:uppercase;word-spacing: 4px; margin-top: 0px;}

  ul {
      list-style: none;
      margin: 0px;
      padding-bottom: 20px;
      display: inline-flex;
      align-items: center;
      font-size: 3em;    
 }
 li { 
     margin: 0px 5px;
     padding:0px;
  }
  section { width:100%;border-bottom: dotted 2px deepskyblue; padding-bottom: 10px;}
  article {border-top: 1px dashed white; padding:20px;}
  
  .set-preview{width:22%;padding:2px;position: relative;display:inline-block;margin:10px;}
  .set-title{position:absolute;bottom:0;right:0;padding:10px;text-align: center;text-transform: uppercase;font-size:12px;font-weight: bold;}
  .picture-thumbnail{ height: 100%;width:100%;margin-top: 2px;box-shadow:-3px 3px 1px #ddf1fb;border: solid #ffffff 5px;}

  /* currentset  */
  .current-set{width:100%;margin-top:0px;padding:2px;position:relative;}
  .current-set-title{position:absolute;top:10;left:10;padding:10px;text-align: center;text-transform: uppercase;font-size:28px;}
  .current-set-cover{ width:100%;margin-top: 2px;box-shadow:-3px 3px 1px #ddf1fb;border: solid #ffffff 5px;}

  .set-photos{
      break-inside: avoid;
      min-width: 400px;

  }

  .thumbnail-mini{ width:95px;height: 95px;margin-top: 2px;display:inline-flex;}
  .thumbnails{padding:15px;position: relative;}


  .selectedViewer {
   max-width: 100%;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-device-width : 482px) and (min-width : 400px) {
  /* Styles */
  html {  margin: 0px auto;
      font-family: 'Open Sans', 'sans-serif';
      background-color:#22272C;color:#FFFFFF;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;font-weight: 600;
      font-size:13px;
  }
  nav {margin: 0px auto; width: 600px}
  section { border-bottom: dotted 2px deepskyblue; padding-bottom: 10px;}
  article {border-top: 1px dashed white; padding:20px;}
  .set-preview{width:125px;padding:2px;position: relative;display:inline-block;margin:10px;}
  .set-title{position:absolute;bottom:0;right:0;padding:10px;text-align: center;text-transform: uppercase;font-size:16px;}
  .picture-thumbnail{ width:124px;height:124px;margin-top: 2px;box-shadow:-3px 3px 1px #ddf1fb;border: solid #ffffff 5px;}
  a {color:#B9D0E1; text-decoration: none;}
  a:hover{color:	#B9D0E1;}
  .thumbnail-mini{ width:95px;height: 95px;margin-top: 2px;display:inline-flex;}
  .thumbnails{margin-left:20px;position: relative;}
  a:visited{color: #B9D0E1;}
  ul {
      list-style: none;
      margin: 0px;
      padding-bottom: 20px;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      
  }
  li { margin: 0px 5px; padding:0px;}


  
  .logo {text-align:center;border:5px solid white;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;width:95px;background-color: white;color:#22272C;}
  .navi {position:relative;line-height: 45px;text-align:center;border:5px solid white;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;width:95px;background-color: white;color:#22272C;padding-bottom: 4px;}
  .navi a {color: #22272C;}
  .navi:hover { border-bottom-color:firebrick;}

  h1 { max-width:50%;margin-left:50px;font-family:Verdana, Geneva, Tahoma, sans-serif;font-size:22px;text-transform:uppercase;word-spacing: 4px; margin-top: 0px;}
  .featured a {color:firebrick;}
  h1.featured {max-width: 100%;font-size:36px;font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;letter-spacing: 2px;}
  
  .excerpt-with-bg {
      width:50%;
      margin-left: 50px;
  }

  .excerpt {
      width:90%;
      margin-left: 50px;
  }

  article time { 
      position:relative;
      float: left;
      margin-top:10px;
  }
  article time span {
      display:block;
      font-size:20px;
      
  }
  .post{max-width:1020px;}
  .post img {max-width: 100%;}
  .post p { break-after: column;}
  /* currentset  */
  .current-set{width:300px;margin-top:0px;padding:2px;position:relative;}
  .current-set-title{position:absolute;top:10;left:10;padding:10px;text-align: center;text-transform: uppercase;font-size:28px;}
  .current-set-cover{ width:300px;height:300px;margin-top: 2px;box-shadow:-3px 3px 1px #ddf1fb;border: solid #ffffff 5px;}

  .set-photos{
      break-inside: avoid;
      min-width: 400px;

  }
  .selectedViewer {
    box-shadow:-3px 3px 1px #ddf1fb;border: solid #ffffff 5px;text-align: center;
    background-color: #212224;
    margin-bottom: 25px;
    padding: 10px;
    position: relative;
    width:50%;
    min-width: 800px;
    
  }
  .selectedPhoto {
    max-width: 100%;
    max-height:80vh;
  }
  .photoNav {
    width: 50%;
    height: 55px;
    box-shadow:-3px 3px 1px #ddf1fb;
    text-align: center;
    background-color: #7e8188;
    border-bottom: dotted 2px deepskyblue;
    text-transform: uppercase;

  }

  .photo-container {
    position: relative; /* Ensure the container is the positioning context */
  padding: 10px; /* Add some padding for spacing */
  }
  .overlay-full {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 95%;
    height: 150px;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    justify-content: center;
    align-items: center;
    color:#FFFFFF;
    font-size: 14px;
  }
  
  .photo-container .icon-overlay {
    position: absolute;
    bottom: 0px;
    right: 0px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    font-size: 30px;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  }
  
  .icon-overlay p {
    display: block;
  }

  .photo-gallery-container {
    display: flex;
    flex-direction: row; /* Aligns children side by side */
  }

  .selectedViewer, .photoSelector, .galleryPreivew {
    flex: 1; 
  }


  .galleryPreivew {
    max-width: 400px;
  min-width: 350px;  }

  .photoSelector {
    max-width: 400px;
  }
  article {
    display: flex;
    flex-direction: row;
    
  }
  
}
